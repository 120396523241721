export const START_TEST = "START_TEST";

export const START_FEEDBACK_TEST = "START_FEEDBACK_TEST";

export const INITIATOR_ANSWERS = "INITIATOR_ANSWERS";

export const REMOVE_INITIATOR_ANSWERS = "REMOVE_INITIATOR_ANSWERS";

export const INITIATOR_CODE= "INITIATOR_CODE";

export const GET_INITIATOR_DETAIL = "GET_INITIATOR_DETAIL";

export const FEEDBACK_ANSWERS = "FEEDBACK_ANSWERS";

export const REMOVE_FEEDBACK_ANSWERS = "REMOVE_FEEDBACK_ANSWERS";